import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { ManageTicket } from '../ManageTicket';
import RepairDetails from '../../Components/RepairDetails';
import { SchoolInvoice } from '../SchoolBilling';
import ShowInvoice from '../SchoolBilling/ShowInvoice';
import { CreateTicket } from '../CreateTicket';
import { FaqIndex } from '../FAQ';
import { ErrorPage } from '../404Error';
export default function Access2() {
    return (
        <Routes>
            <Route exact path="/" element={<ManageTicket />} />
            <Route exact path="/manage-tickets/*" element={<ManageTicket />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route exact path="/school-invoice" element={<SchoolInvoice />} />
            <Route exact path="/generate-invoice" element={<ShowInvoice />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
