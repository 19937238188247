import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { ManageTicket } from '../ManageTicket';
import RepairDetails from '../../Components/RepairDetails';
import { ManageDeviceIndex } from '../ManageDevice';
import { AddUpdate } from '../ManageDevice/addupdate';
import { CreateTicket } from '../CreateTicket';
import { FaqIndex } from '../FAQ';
import { ErrorPage } from '../404Error';
export default function Access7() {
    return (
        <Routes>
            <Route exact path="/" element={<ManageTicket />} />
            <Route exact path="/manage-tickets/*" element={<ManageTicket />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route path="/manage-device/*" element={<ManageDeviceIndex />} />
            <Route path="/addupdate-device/*" element={<AddUpdate />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
