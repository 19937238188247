import React, { useEffect, useState } from 'react'
import { ApiGetCallAuthKey } from '../../JS/Connector';
import NoContractOption from './NoContractOption';
import YesContractOption from './YesContractOption';
import { MMDDYYYY } from '../../JS/Common';
function Contract() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[3];
    const [GradeArray, setGradeArray] = useState([]);
    const [AllPlanName, setAllPlanName] = useState([]);
    const [PolicyAgreeFlag, setPolicyAgreeFlag] = useState(0)
    const [UncoveredPrice, setUncoveredPrice] = useState("")
    const [RepairChargeFlag, setRepairChargeFlag] = useState("")
    const [EnrollmentTypeFlag, setEnrollmentTypeFlag] = useState('')
    const [insurancepolicyPdf, setinsurancepolicyPdf] = useState(null)
    const [purchaseYesPlanFlag, setPurchaseYesPlanFlag] = useState(3)
    const [ErrorMsgForFairPolicy, setErrorMsgForFairPolicy] = useState('')
    const [SchoolId, setSchoolId] = useState('')
    const [SchoolName, setSchoolName] = useState('')
    const [SchoolLogo, setSchoolLogo] = useState('')
    const [checkboxdisable, setcheckboxdisable] = useState(false)
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const [planGrades, setPlanGrades] = useState([])
    const [planGradeVal, setplanGradeVal] = useState('')
    const [PlanStatusFlag, setPlanStatusFlag] = useState('')
    const [Description, setDescription] = useState('')
    const [YesNodiv, setYesNodiv] = useState(false)
    const [ShowForm, setShowForm] = useState(0)

    useEffect(() => {
        ApiGetCallAuthKey("/schoolDatabyNumber/" + schoolnumber).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setSchoolId(responseRs?.msg?.id)
                    setSchoolLogo(responseRs?.logo)
                    setSchoolName(responseRs?.msg?.name)
                    setinsurancepolicyPdf(responseRs?.msg?.insurance_policy)
                    if (responseRs?.msg?.insurance_policy == null) {
                        setShowForm(1)
                    }
                    setDescription(responseRs?.msg?.insurance_notes);
                    ApiGetCallAuthKey("/allGradeandBuilding/" + responseRs?.msg?.id).then((result) => {
                        if (result == undefined || result == "") {
                            alert("Something went wrong");
                        } else {
                            const responseRs3 = JSON.parse(result);
                            if (responseRs3.response == "success") {
                                setGradeArray(responseRs3.grade);
                            }
                        }
                    });

                    ApiGetCallAuthKey("/getAllGradeOfParentalPlan/" + responseRs?.msg?.id).then((result) => {
                        if (result == undefined || result == "") {
                            alert("Something went wrong");
                        } else {
                            const responseRs1 = JSON.parse(result);
                            setPlanGrades(responseRs1)
                        }
                    });
                }
            }
        });
    }, []);
    async function GetLiveAllPlanCall(grade) {
        await ApiGetCallAuthKey("/getAllLivePlanBySchoolId/" + SchoolId + '&' + grade).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs2 = JSON.parse(result);
                if (responseRs2.status == "success") {
                    if (responseRs2.data.length == 0) {
                        setAllPlanName([])
                    } else {
                        setYesNodiv(true)
                        setAllPlanName(responseRs2.data[0])
                        setUncoveredPrice(responseRs2.data[0].uncovered_charge)
                        setRepairChargeFlag(responseRs2.data[0]?.school?.uncovered_repair_charges)
                        setPlanStatusFlag(responseRs2.data[0].planFlag)
                        const currentDate = new Date();
                        const year = currentDate.getFullYear();
                        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(currentDate.getDate()).padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day}`;
                        if (responseRs2.data[0].end_date >= formattedDate) {
                            setEnrollmentTypeFlag(1)
                        } else {
                            setEnrollmentTypeFlag(2)
                        }
                    }
                }
            }
        });
    }
    function scrollToSignUp() {
        if (insurancepolicyPdf != null) {
            if (PolicyAgreeFlag == 0) {
                setErrorMsgForFairPolicy('You need to acknowledge the Fair Use Policy before continuing with your purchase.')
                setcheckboxdisable(false);
            } else if (PolicyAgreeFlag == 1) {
                setShowForm(1)
                const element = document.getElementById("signUpForm");
                element.scrollIntoView({ behavior: "smooth" });
                setErrorMsgForFairPolicy('')
                setcheckboxdisable(true);
            } else {
                setErrorMsgForFairPolicy('')
            }
        } else {
            setShowForm(1)
            const element = document.getElementById("signUpForm");
            element.scrollIntoView({ behavior: "smooth" });
            setErrorMsgForFairPolicy('')
        }

    }
    const openPDFInNewTab = () => {
        const link = document.createElement('a');
        link.href = `${BucketS3Link}${insurancepolicyPdf}`;
        window.open(link.href, '_blank');
    };
    return (
        <div>
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className={window.innerWidth >= 767 ? 'm-3 px-1' : ''}>
                        <div className={window.innerWidth >= 767 ? 'GridBox p-4 px-5 m-auto mx-5' : 'GridBox p-4 px-5 m-auto'}>
                            <div className="row pt-3 pb-3 align-items-center">
                                <div className='col-md-12 text-center'>
                                    {SchoolLogo ?
                                        <img src={SchoolLogo} className="logo-icon" alt="logo icon" style={{ maxHeight: '70px', maxWidth: '70px' }} />
                                        :
                                        <h4 className='fw-600' style={{ color: '#3cbba5' }}>{SchoolName}</h4>
                                    }
                                </div>
                            </div>
                            {insurancepolicyPdf != null && (
                                <div className='pb-3' >
                                    <div className='col-md-12 text-center font-16 fw-600'>
                                        Before you purchase the Device Protection Plan, you must agree to our Fair Use Policy.<br /> You can read the  <u className='cursor-pointer' style={{ color: "#04ADFD", fontWeight: "600" }} onClick={(e) => openPDFInNewTab(e)}>Fair Use Policy here.</u>
                                    </div>
                                    <div className='col-12 d-flex align-items-center justify-content-center mt-3'>
                                        <input className='form-check-input me-2 cursor-pointer' type="checkbox" disabled={checkboxdisable}
                                            onChange={(e) => { setErrorMsgForFairPolicy(''); setPolicyAgreeFlag(e.target.checked ? 1 : 0); setShowForm(e.target.checked ? 1 : 0); e.target.checked ? setcheckboxdisable(true) : setcheckboxdisable(false) }} />
                                        <p className="mb-0 text-start"> I acknowledge the Fair Use Policy.</p>
                                    </div>
                                    <div className='Emailinvalid text-center'>{ErrorMsgForFairPolicy}</div>
                                </div>
                            )}
                            <div className="greyBox p-4 pe-0">
                                <div className='row align-items-center'>
                                    <div className='col-md-7 text-justify'>
                                        <h4 className='fw-600 mb-4'>{SchoolName} Device Protection Plans, Charger, Protective Case, And Repair Purchase Portal.</h4>
                                        <h6 className='text-justify'>Keep your important electronic devices safe with the K-12 Tech Student Device Protection Plan.</h6>
                                        <button className='GetStarted my-3 cursor-pointer ' onClick={scrollToSignUp}>Get Started</button>
                                        <h6 className='my-2' style={{ color: "#4C5258", fontSize: "13px" }}>*The coverage begins on the first day of school and lasts until the end, with the duration depending on the chosen Protection Plan. This is available for students in grades K-12.</h6>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='position-relative'>
                                            <div className='col-md-8' style={{ float: 'right' }}> <img src="/images/Contractimg.svg" className="img-fluid" style={{ display: 'block', width: '100%' }} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='my-4 col-12 text-center'>
                                <img src='/images/contractcovered.svg' className='img-fluid my-5' />
                            </div>
                            <div className='row text-center'>
                                <h2 className='fw-600 mb-0'>SEE <span className='whats-cover-text'>WHAT'S NOT COVERED </span>BY OUR PROGRAM.</h2>
                            </div>
                            <div className='row pt-5'>
                                <div className='col-md-12 text-center pb-4'>
                                    <img src="/images/Contractimg2.svg" className="img-fluid" style={{ borderRadius: "10px" }} />
                                </div>
                                <div className='col-md-10 pffset-md-1 m-auto py-3 text-justify'>
                                    <div className='col-md-12 text-center'>
                                        <h3 className='fw-600'><u>Claim Process</u></h3>
                                    </div>
                                    <ul>
                                        <li className='claim-process-content mt-4'>If you have a claim, please drop the device with your school’s technology department, and we will arrange for regular pickups to repair the devices.</li>
                                        <li className='claim-process-content mt-4'>Once they are repaired, they will be delivered back to the school’s technology department and distributed back to you.</li>
                                    </ul>
                                </div>
                            </div>
                            {ShowForm == 1 && (
                                <div className='pt-3' id="signUpForm">
                                    <div>
                                        <h3 className='fw-600 text-center' style={{ color: '#3CBBA5' }}><u>Sign Up Form</u></h3>
                                        <div className='col-md-10 offset-md-1 parent' id='addProtectionForm'>
                                            <div className='text-center'>
                                                <label className='font-16 fw-600 pt-2 '>What grade is your student entering?</label></div>
                                            <div className='text-center pb-3 col-md-4 offset-md-4'>
                                                <div className=" ms-2 py-1 pe-0">
                                                    <select disabled={purchaseYesPlanFlag != 3} value={planGradeVal} onChange={(e) => { setplanGradeVal(e.target.value); GetLiveAllPlanCall(e.target.value) }}>
                                                        <option value="">Filter by Grade</option>
                                                        {planGrades.map((item, j) => (
                                                            <option value={item} key={j}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {planGradeVal != '' && (
                                                <>
                                                    <div className={`p-3 row GridBox`}>
                                                        <div className='col-md-6'>
                                                            Plan : <label className='fw-600 schoolColorStatus'>{AllPlanName.plan_name}</label>
                                                        </div>
                                                        <div className='col-md-6 text-end pt-2'>
                                                            Prices : <label className='fw-600'>${AllPlanName.price || 0}</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            Sign Up Start Date: : <label className='fw-600'>{MMDDYYYY(AllPlanName.start_date)}</label>
                                                        </div>
                                                        <div className='col-md-6 text-end pt-2'>
                                                            Sign Up End Date: : <label className='fw-600'>{MMDDYYYY(AllPlanName.end_date)}</label>
                                                        </div>
                                                    </div>
                                                    {YesNodiv && (
                                                        <div className='text-center py-3'>
                                                            <label className='font-16 fw-600'>Are you wanting to purchase coverage?</label>
                                                            <div>
                                                                <div className='col-md-12 mt-2 d-flex align-items-center justify-content-center'>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name='purchase'
                                                                            onChange={(e) => { setPurchaseYesPlanFlag(1) }} />
                                                                        <label className="form-check-label">Yes</label>
                                                                    </div>
                                                                    <div className="form-check ms-5">
                                                                        <input className="form-check-input" type="radio" name='purchase' value='no'
                                                                            onChange={(e) => { setPurchaseYesPlanFlag(2); }} />
                                                                        <label className="form-check-label">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {purchaseYesPlanFlag != 3 && (
                                                <>
                                                    <>{purchaseYesPlanFlag == 2 && (<><NoContractOption Description={Description} planflag={PlanStatusFlag} planGradeVal={planGradeVal} PolicyAgreeFlag={PolicyAgreeFlag} UncoveredPrice={UncoveredPrice} planid={AllPlanName.id} GradeArray={GradeArray} SchoolId={SchoolId} repaircharge={RepairChargeFlag} /></>)}</>
                                                    <>{purchaseYesPlanFlag == 1 && (<><YesContractOption Description={Description} planflag={PlanStatusFlag} planGradeVal={planGradeVal} PolicyAgreeFlag={PolicyAgreeFlag} EnrollmentTypeFlag={EnrollmentTypeFlag} planid={AllPlanName.id} price={AllPlanName.price} setEnrollmentTypeFlag={setEnrollmentTypeFlag} GradeArray={GradeArray} SchoolId={SchoolId} startdate={AllPlanName.start_date} planname={AllPlanName.plan_name} /></>)}</>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Contract
