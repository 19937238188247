import React, { useEffect, useState } from 'react'
import { HideLoder, LogInSchoolID, ShowLoder, formatDateToMMDDYYYY, typewatch } from '../../JS/Common';
import { ApiGetCall } from '../../JS/Connector';
import { DeviceDetailsPopup } from '../../Components/DeviceDetailsPopup';
import TicketDetailsPopup from '../../Components/TicketDetailsPopup';
import Pagination from "react-js-pagination";

export default function AllTickets() {
    const [GridDataForAllOpen, setGridDataForAllOpen] = useState([])
    const [GridDataForAllClose, setGridDataForAllClose] = useState([])
    const [SearchForAllOpen, setSearchForAllOpen] = useState('')
    const [SearchForAllClose, setSearchForAllClose] = useState('')
    const [loading, setLoading] = useState(false);
    const [isModal, setisModal] = useState({
        isshowpopup: false,
        isTicketDetailspopup: false,
    });
    const [TicketParams, setTicketParams] = useState({
        inventoryId: "",
        ticketId: "",
    });
    const showPaginationForOpen = GridDataForAllOpen.length > 0;
    const showPaginationForClose = GridDataForAllClose.length > 0;
    const [currentPage1, setCurrentPage1] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [pageRangeCountForOpen, setpageRangeCountForOpen] = useState(0);
    const [pageRangeCountForClose, setpageRangeCountForClose] = useState(0);
    const [LimitForPagination1, setLimitForPagination1] = useState(25);
    const [LimitForPagination2, setLimitForPagination2] = useState(25);

    useEffect(() => {
        GetAllTicketCall(null, null, null)
    }, [currentPage1, LimitForPagination1, currentPage2, LimitForPagination2])

    async function GetAllTicketCall(flag, searchForOpen, searchForClose) {
        searchForOpen = searchForOpen || null;
        searchForClose = searchForClose || null;
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allTickets/" + LogInSchoolID + "&" + flag + "&" + searchForOpen + '&' + searchForClose + '&' + LimitForPagination1 + '&' + LimitForPagination2 + '&' + currentPage1 + '&' + currentPage2).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = JSON.parse(result);
                    setpageRangeCountForOpen(responseRs.dataForOpen.total)
                    setpageRangeCountForClose(responseRs.dataForClose.total)
                    setGridDataForAllOpen(responseRs.dataForOpen.data.length ? responseRs.dataForOpen.data : [])
                    setGridDataForAllClose(responseRs.dataForClose.data.length ? responseRs.dataForClose.data : [])
                }
            });
        }, 500);
    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row mt-2 px-3">
                <div className="col-md-6 py-1">
                    <div className="greyBox">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Header mt-2">
                                    <b className="font-15">Open Tickets</b>
                                    <br />
                                    <img
                                        src="/images/HorizontalLine.svg"
                                        className="img-fluid w-100"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8 text-end pb-2 row pe-0 align-items-center">
                                <div className="px-1 col-md-8 text-end font-13">Items per page:</div>
                                <div className="col-md-4 px-0"><select value={LimitForPagination1} onChange={(e) => { setLimitForPagination1(e.target.value); setCurrentPage1(1); }}>
                                    {[25, 50, 100, 150, 200].map(value => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            <div className="col-md-4 mx-2 text-end pe-0">
                                <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" value={SearchForAllOpen}
                                        onChange={(e) => { setSearchForAllOpen(e.target.value) }}
                                        onKeyUp={(e) => GetAllTicketCall(1, e.target.value, null)}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="data-table-container">
                            <table className="table data-table mb-0">
                                <thead className="GridHeader">
                                    <tr>
                                        <th scope="col" className="cursor-pointer fw-600">  Ticket #  </th>
                                        <th scope="col" className="cursor-pointer fw-600"> Serial # </th>
                                        <th scope="col" className="cursor-pointer fw-600"> Created Date </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {GridDataForAllOpen.length != 0 ? (
                                        GridDataForAllOpen.map((item, i) => {
                                            return (
                                                <tr key={i} className="OddEvenClass tableBorderHide"  >
                                                    <td className="py-12"
                                                        onClick={(e) => { setisModal({ ...isModal, isTicketDetailspopup: true, }); setTicketParams({ ...TicketParams, ticketId: item.id, }); }}
                                                    >
                                                        <u>{item.ticket_num}</u>
                                                    </td>
                                                    <td className="cursor-pointer py-12" title="Show Device Details"
                                                        onClick={(e) => { setisModal({ ...isModal, isshowpopup: true, }); setTicketParams({ ...TicketParams, inventoryId: item.inventory_id, }); }}
                                                    >
                                                        <u>{item?.inventory_management?.serial_number}</u>
                                                    </td>
                                                    <td className="py-12">{formatDateToMMDDYYYY(item.created_at)}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr><td colSpan={3} className="p-3 text-center"> No Record Found</td></tr>
                                    )}
                                </tbody>
                            </table>
                            {showPaginationForOpen && (
                                <Pagination
                                    activePage={currentPage1}
                                    totalItemsCount={pageRangeCountForOpen}
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={LimitForPagination1}
                                    onChange={(e) => setCurrentPage1(e)}
                                    color="primary"
                                    lastPageText=""
                                    firstPageText=""
                                    itemClassFirst="MasterClass"
                                    itemClassPrev="MasterClass"
                                    itemClassNext="MasterClass"
                                    itemClassLast="MasterClass"
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 py-1">
                    <div className="greyBox">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Header mt-2">
                                    <b className="font-15">Closed Tickets</b>
                                    <br />
                                    <img src="/images/HorizontalLine.svg" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col-md-8 text-end pb-2 row pe-0 align-items-center">
                                <div className="px-1 col-md-8 text-end font-13">Items per page:</div>
                                <div className="col-md-4 px-0"><select value={LimitForPagination2} onChange={(e) => { setLimitForPagination2(e.target.value); setCurrentPage2(1); }}>
                                    {[25, 50, 100, 150, 200].map(value => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            <div className="col-md-4 text-end pe-0 mx-2">
                                <form className="gridsearchbar">
                                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" value={SearchForAllClose}
                                        onChange={(e) => { setSearchForAllClose(e.target.value) }}
                                        onKeyUp={(e) => GetAllTicketCall(2, null, e.target.value)} />
                                </form>
                            </div>
                        </div>
                        <div className="data-table-container">
                            <table className=" mb-0 table data-table">
                                <thead className="GridHeader">
                                    <tr>
                                        <th scope="col" className="cursor-pointer fw-600" title="Sort by" > Ticket # </th>
                                        <th scope="col" className="cursor-pointer fw-600" title="Sort by"  >  Serial #</th>
                                        <th scope="col" className="cursor-pointer fw-600" title="Sort by"    >  Created Date   </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {GridDataForAllClose.length != 0 ? (
                                        GridDataForAllClose.map((item, i) => {
                                            return (
                                                <tr key={i} className="OddEvenClass tableBorderHide"  >
                                                    <td className="py-12"
                                                        onClick={(e) => { setisModal({ ...isModal, isTicketDetailspopup: true, }); setTicketParams({ ...TicketParams, ticketId: item.id, }); }}
                                                    >
                                                        <u>{item.ticket_num}</u>
                                                    </td>
                                                    <td className="cursor-pointer py-12" title="Show Device Details"
                                                        onClick={(e) => { setisModal({ ...isModal, isshowpopup: true, }); setTicketParams({ ...TicketParams, inventoryId: item.inventory_id, }); }}
                                                    >
                                                        <u>{item?.inventory_management?.serial_number}</u>
                                                    </td>
                                                    <td className="py-12">{formatDateToMMDDYYYY(item.created_at)}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr><td colSpan={3} className="p-3 text-center">  No Record Found </td></tr>
                                    )}
                                </tbody>
                            </table>
                            {showPaginationForClose && (
                                <Pagination
                                    activePage={currentPage2}
                                    totalItemsCount={pageRangeCountForClose}
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={LimitForPagination2}
                                    onChange={(e) => setCurrentPage2(e)}
                                    color="primary"
                                    lastPageText=""
                                    firstPageText=""
                                    itemClassFirst="MasterClass"
                                    itemClassPrev="MasterClass"
                                    itemClassNext="MasterClass"
                                    itemClassLast="MasterClass"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isModal.isshowpopup == true && (
                <DeviceDetailsPopup Showbuttons={1} ticketid={TicketParams.ticketId} createticketbutton={true} deviceid={TicketParams.inventoryId} activetab="device" isShow={isModal.isshowpopup} ClosePopup={(e) => setisModal({ ...isModal, isshowpopup: false })} />
            )}
            {isModal.isTicketDetailspopup == true && (
                <TicketDetailsPopup isShow={isModal.isTicketDetailspopup} ticketid={TicketParams.ticketId} inventoryid={TicketParams.inventoryId} ClosePopup={(e) => setisModal({ ...isModal, isTicketDetailspopup: false })} />
            )}
        </div>
    )
}
