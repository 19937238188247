import './index.js';
import './App.css';
import './Styles/Common.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './JS/Connector.js';
import './JS/Common.js';
import { BrowserRouter as Router } from "react-router-dom";
import React, { useState, createContext, useEffect } from 'react';
import { ApiGetCallAuthKey } from './JS/Connector.js';
import { Sidemenu } from './Components/Sidemenu.jsx';
import { RemoveAllCookies } from './JS/Common.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';
import GlobalStyles from './Components/GlobalStyles.jsx';
import Access1 from './Layout/Routers/Access1.jsx';
import Access2 from './Layout/Routers/Access2.jsx';
import Access5 from './Layout/Routers/Access5.jsx';
import Access6 from './Layout/Routers/Access6.jsx';
import Access7 from './Layout/Routers/Access7.jsx';
import Access8 from './Layout/Routers/Access8.jsx';
import Access9 from './Layout/Routers/Access9.jsx';
import Access10 from './Layout/Routers/Access10.jsx';
import Access11 from './Layout/Routers/Access11.jsx';
import UnauthenticatedRoutes from './Layout/Routers/UnauthenticatedRoutes.jsx';
import MatchingPath, { pathsToMatch } from './Layout/Routers/MatchingPath.jsx';
export const UserContext = createContext();
function App() {
  var CsvUserId = Cookies.get('CsvUserId');
  const clientId = process.env.REACT_APP_GoogleClientId;
  const [menuData, setMenuData] = useState([]);
  const [UserData, setUserData] = useState({
    emailid: "",
    UserName: "",
    MenuAccessFlag: "",
    Accesstoken: "",
    inventoryflag: '',
    ssoFlog: '',
    InsurancePolicy: '',
    sendOutForRepairFlag: '',
    signatureFlag: '',
    helpdeskflag: '',
    helpdeskUpdatedFlag: '',
    copyaccesstype: '',
    repairflag: '',
    schoollogo: '',
    schoolname: '',
    PaymentLink: '',
    PaymentLinkFlag: ''
  });
  const pathname = window.location.pathname;
  const pathArray = pathname.split('/');
  const path = pathArray[1];
  useEffect(() => {
    if (path != "k12logout") {
      if (CsvUserId != null) {
        setTimeout(() => {
          getMenudata();
        }, 1500);
      }
    }
  }, [CsvUserId]);
  const getMenudata = async () => {
    await ApiGetCallAuthKey("/menuAccess/" + parseInt(Cookies.get('CsvUserId'))).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          if (responseRs.msg.length != 0) {
            setMenuData(responseRs.msg);
            setUserData({
              ...UserData, emailid: responseRs.user.email, UserName: responseRs.user.first_name + " " + responseRs.user.last_name,
              MenuAccessFlag: responseRs.user.access_type, Accesstoken: responseRs.user.remember_token, inventoryflag: responseRs.inventoryflag,
              ssoFlog: responseRs.ssoFlog, InsurancePolicy: responseRs.user?.school?.insurance_policy,
              sendOutForRepairFlag: responseRs.defaultTicketStaus, signatureFlag: responseRs?.signatureFlag, helpdeskflag: responseRs.helpdeskflag,
              helpdeskUpdatedFlag: responseRs.helpdesk_updated_at, copyaccesstype: responseRs.user.copy_access_type,
              repairflag: responseRs.uncovered_repair_charges, schoollogo: responseRs.logo || '/images/logo-icon.svg',
              schoolname: responseRs?.user?.school?.name, PaymentLink: responseRs?.defaultPaymentLink,
              PaymentLinkFlag: responseRs?.paymentLinkFlag
            })
          }
          Cookies.set('schoolid', responseRs?.user?.school_id, { expires: 1 });
          Cookies.set('ShippingType', responseRs?.user?.school?.shipping_type, { expires: 1 });
        } else {
          RemoveAllCookies();
          window.location.href = "/";
        }
      }
    });
  }
  const getRoutesComponent = () => {
    switch (UserData.MenuAccessFlag) {
      case 1: //school admin
        return <Access1 />;
      case 2: //school repair tech
        return <Access2 />;
      case 5: //k12 admin
        return <Access5 />;
      case 6: //k12 repair tech
        return <Access6 />;
      case 7: //K12 To School Repair Tech
        return <Access7 />;
      case 8: //Teacher
        return <Access8 />;
      case 9://Maintenance Staff
        return <Access9 />;
      case 10: //Reports Only
        return <Access10 />;
      case 11://Billing
        return <Access11 />;
    }
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GlobalStyles />
      <Router>
        {pathsToMatch.includes(path) ?
          <MatchingPath path={pathArray[1]} />
          :
          CsvUserId != null ?
            <UserContext.Provider value={{ menuData, UserData, setUserData }}>
              <Sidemenu>
                {getRoutesComponent()}
              </Sidemenu>
            </UserContext.Provider>
            :
            <UnauthenticatedRoutes />
        }
      </Router>
    </GoogleOAuthProvider>
  )
}

export default App;
